/**
 * Custom Responsive SVG Stroke
 */

 @each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $color, $value in $colors {
      .stroke#{$infix}-#{$color} {stroke: $value !important}
    }
    @each $color, $value in $theme-colors {
      .stroke#{$infix}-#{$color} {stroke: $value !important}
    }
  }
}