/**
 * Custom Responsive Color Utilities
 */

 @each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $color, $value in $all-colors {
      .text#{$infix}-#{$color} {
        color: $value !important;
        &::placeholder {
        color: $value !important;          
        }
      }
    }
  }
}