.segment-full {
  stroke-width: 3;
  stroke-miterlimit: 10;
}

.segment-empty {
  fill: none;
  stroke-width: 4;
  stroke-miterlimit: 10;
}
