/**
 * Custom Responsive SVG Fill
 */

 @each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $color, $value in $all-colors {
      .fill#{$infix}-#{$color} {fill: $value !important}
    }
  }
}