/**
 * Custom Responsive BG Color Utilities
 */

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $color, $value in $all-colors {
      .hover-bg#{$infix}-#{$color} {
        &:hover {
          background-color: $value !important;
        }
      }
      .hover-text#{$infix}-#{$color} {
        &:hover {
          color: $value !important;
        }
      }
      .hover-fill#{$infix}-#{$color} {
        &:hover {
          fill: $value !important;
        }
      }
    }
  }
}
