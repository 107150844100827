/**
 * Custom Responsive Button Hover states
 */

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .border#{$infix} {
      border: $border-width solid $border-color !important;
    }
    .border#{$infix}-top {
      border-top: $border-width solid $border-color !important;
    }
    .border#{$infix}-right {
      border-right: $border-width solid $border-color !important;
    }
    .border#{$infix}-bottom {
      border-bottom: $border-width solid $border-color !important;
    }
    .border#{$infix}-left {
      border-left: $border-width solid $border-color !important;
    }
    .border#{$infix}-x {
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }
    .border#{$infix}-y {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
    }

    .border-0#{$infix} {
      border: 0 !important;
    }
    .border-top-0#{$infix} {
      border-top: 0 !important;
    }
    .border-right-0#{$infix} {
      border-right: 0 !important;
    }
    .border-bottom-0#{$infix} {
      border-bottom: 0 !important;
    }
    .border-left-0#{$infix} {
      border-left: 0 !important;
    }
    .border-x-0#{$infix} {
      border-left: 0 !important;
      border-right: 0 !important;
    }
    .border-y-0#{$infix} {
      border-bottom: 0 !important;
      border-top: 0 !important;
    }


    @each $color, $value in $all-colors {
      .border#{$infix}-#{$color} {
        border: $border-width solid $value !important;
      }
      .border#{$infix}-top-#{$color} {
        border-top: $border-width solid $value !important;
      }
      .border#{$infix}-right-#{$color} {
        border-right: $border-width solid $value !important;
      }
      .border#{$infix}-bottom-#{$color} {
        border-bottom: $border-width solid $value !important;
      }
      .border#{$infix}-left-#{$color} {
        border-left: $border-width solid $value !important;
      }
      .border#{$infix}-y-#{$color} {
        border-bottom: $border-width solid $value !important;
        border-top: $border-width solid $value !important;
      }
      .border#{$infix}-x-#{$color} {
        border-right: $border-width solid $value !important;
        border-left: $border-width solid $value !important;
      }
    }
  }
}
