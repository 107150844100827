/**
 *
 * Breakpoint Based Typography
 *
 */

 @each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .h1#{$infix} {
      font-size: $h1-font-size !important;
    }
    .h2#{$infix} {
      font-size: $h2-font-size !important;
    }
    .h3#{$infix} {
      font-size: $h3-font-size !important;
    }
    .h4#{$infix} {
      font-size: $h4-font-size !important;
    }
    .h5#{$infix} {
      font-size: $h5-font-size !important;
    }
    .h6#{$infix} {
      font-size: $h6-font-size !important;
    }
    .paragraph#{$infix} {
      font-size: $font-size-base !important;
    }
    .paragraph-small#{$infix} {
      font-size: $font-size-sm !important;
    }
    .paragraph-tiny#{$infix} {
      font-size: ($font-size-sm * 0.75)!important;
    }
  }
}
