/**
 * Custom Responsive Box Arrow
 */
$all-colors: map-merge($theme-colors, $colors);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $color, $value in $all-colors {
      .box-arrow-top#{$infix}-#{$color} {
        &::after {
          z-index: -1;
          position: absolute;
          top: 100%;
          content: "";
          width: 0;
          height: 0;
          border-top: solid 1em $value;
          border-left: solid thick transparent;
          border-right: solid thick transparent;
        }
      }
      .box-arrow-bottom#{$infix}-#{$color} {
        &::after {
          z-index: -1;
          position: absolute;
          top: 100%;
          content: "";
          width: 100%;
          height: 100%;
          border-top: solid 2rem $value;
          border-left: solid 6rem transparent;
          border-right: solid 6rem transparent;
        }
      }
      .box-arrow-left#{$infix}-#{$color} {
        &::after {
          z-index: -1;
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-top: solid thick $value;
          border-left: solid thick transparent;
          border-right: solid thick transparent;
        }
      }
      .box-arrow-right#{$infix}-#{$color} {
        &::after {
          z-index: -1;
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-top: solid thick $value;
          border-left: solid thick transparent;
          border-right: solid thick transparent;
        }
      }
    }
  }
}
