/**
 * Control the Display of Background images at breakpoints
 */

 @each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .bg-img#{$infix}-down-clear { background-image: none !important; }
  }
}