/**
 * Custom Responsive BG Color Utilities
 */

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .ws-wrap#{$infix} {
      white-space: normal !important;
    }
    .ws-nowrap#{$infix} {
      white-space: nowrap !important;
    }
  }
}
