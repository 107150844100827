/**
 * Custom Aspect Ratio components
 */

@use "sass:math";

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .aspect-ratio#{$infix} {
      position: relative;
      display: block;
      width: 100%;
      padding: 0;
      overflow: hidden;

      &::before {
        display: block;
        content: "";
      }

      .aspect-ratio-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }

    .aspect-ratio-21by9#{$infix} {
      &::before {
        padding-top: percentage(math.div(9, 21));
      }
    }
    .aspect-ratio-16by9#{$infix} {
      &::before {
        padding-top: percentage(math.div(9, 16));
      }
    }
    .aspect-ratio-30by10#{$infix} {
      &::before {
        padding-top: percentage(math.div(10, 30));
      }
    }
    .aspect-ratio-2by1#{$infix} {
      &::before {
        padding-top: percentage(1 * 0.5);
      }
    }
    .aspect-ratio-vertical-rect#{$infix} {
      &::before {
        padding-top: percentage(math.div(1.075, 1));
      }
    }
    .aspect-ratio-5by2#{$infix} {
      &::before {
        padding-top: percentage(2 * 0.2);
      }
    }
    .aspect-ratio-5by1#{$infix} {
      &::before {
        padding-top: percentage(1 * 0.2);
      }
    }
    .aspect-ratio-4by3#{$infix} {
      &::before {
        padding-top: percentage(3 * 0.25);
      }
    }
    .aspect-ratio-9by16#{$infix} {
      &::before {
        padding-top: percentage(math.div(16, 9));
      }
    }
  }
}
