/**
 * Custom Responsive BG Color Utilities
 */

 @each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $color, $value in $all-colors {
      .bg#{$infix}-#{$color} {background-color: $value !important}
    }
  }
}