/**
 * Custom opacity states
 */

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .opacity-full#{$infix} {
      opacity: 1 !important;
    }
    .opacity-weak#{$infix} {
      opacity: .25 !important;
    }
    .opacity-faded#{$infix} {
      opacity: .5 !important;
    }
    .opacity-strong#{$infix} {
      opacity: .75 !important;
    }
  }
}
