/**
 * Theme SCSS
 *
 * Use this file to overwrite variables decalred in the bootstrap variables file
 *
 * this file is intended to form the base styles of the engine room application
 *
 * PLEASE NOTE:
 * DO NOT add styles into this file for a specific component
 * you should consider all components as self contained applications
 * 
*/

/**
 * Theme Overrides
 *
 * Theme overrides have been designed graphically using https://bootstrap.build/app/v4.0/
 *
 * to preview changes to the underlying bootsrap theme please consider 
 * uploading the theme overrides below in a _variables.scss file
*/

$enable-responsive-font-sizes: true;

$red: #cf0000;
$orange: #ea7525;
$yellow: #eec245;
$green: #24a21a;
$cyan: #2492d2;
$darkgray: #5e5d63;
$lightwhite: #f4f4f4;
$primary: $orange;
$secondary: lighten($darkgray, 50);
$danger: $red;
$enable-rounded: true;
$border-radius: 0.15rem;
$font-size-base: 1rem;
$font-size-lg: ($font-size-base * 1.35);
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.15;
$font-weight-normal: 400;
$headings-font-weight: $font-weight-normal;
$alert-bg-level: -10;
$alert-border-level: 5;
$alert-color-level: 5;
$navbar-padding-y: 0px;
$dropdown-spacer: 0px;

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
  ),
  $spacers
);

:export {
  red: $red;
  orange: $orange;
  yellow: $yellow;
  green: $green;
  cyan: $cyan;
  darkgray: $darkgray;
  lightwhite: $lightwhite;
  primary: $primary;
  secondary: $secondary;
  danger: $danger;
  enable-rounded: $enable-rounded;
  border-radius: $border-radius;
  font-size-base: $font-size-base;
  font-size-lg: $font-size-lg;
  h1-font-size: $h1-font-size;
  h2-font-size: $h2-font-size;
  h3-font-size: $h3-font-size;
  h4-font-size: $h4-font-size;
  h5-font-size: $h5-font-size;
  font-weight-normal: $font-weight-normal;
  headings-font-weight: $headings-font-weight;
  alert-bg-level: $alert-bg-level;
  alert-border-level: $alert-border-level;
  alert-color-level: $alert-color-level;
  navbar-padding-y: $navbar-padding-y;
  dropdown-spacer: $dropdown-spacer;
  spacer: $spacer;
}
