//
//  note: Most of this is temporary and will be refactored into
//  bootstrap style modules
//  TODO:
//  * Bootstrapify everything
//  * Increase the font size or make it responsive (assuming 16:9 resolution will be used)

// Our Theme Var Overrides
@import "./theme.module";

// Bootstrap library
@import "~bootstrap/scss/bootstrap";

// implement any custom vars
$custom-colors: () !default;
$custom-colors: map-merge(
  (
    "light-gray": #d8d8d8,
    "light-white": $lightwhite,
    "dark-gray": $darkgray
  ),
  $custom-colors
);
$colors: map-merge($custom-colors, $colors);
$all-colors: map-merge($theme-colors, $colors);

/**
 * SCSS Boostrap Modules
 * 
 * This File contains an include referenc for all custom SCSS modules built for the solar aschools public site.
 */

// Import our Botstrap Extensions
@import "../../../styles/modules/aspect_ratios";
@import "../../../styles/modules/indents";
@import "../../../styles/modules/typography";
@import "../../../styles/modules/hover_states";
@import "../../../styles/modules/bg_img";
@import "../../../styles/modules/responsive_bg_colors";
@import "../../../styles/modules/responsive_text_colors";
@import "../../../styles/modules/responsive_svg_fill";
@import "../../../styles/modules/responsive_svg_stroke";
@import "../../../styles/modules/responsive_box_arrow";
@import "../../../styles/modules/responsive_whitespace";
@import "../../../styles/modules/opacity";
@import "../../../styles/modules/layering";
@import "../../../styles/modules/responsive_borders";
@import "../../../styles/modules/responsive_gutters";
@import "../../../styles/modules/utilities";

*,
*::before,
*::after {
  color: inherit;
}

// MODULES
@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

// DEFAULTS
html,
body {
  // PUT NOVEL SANS PRO (LICENSED)
  color: gray;
  min-width: 500px;
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

// GENERIC
.vertical-align {
  @include vertical-align();
}

.white-text {
  color: white;
}

.chart-title {
  font-size: 3rem;
}

.rounded-massive {
  border-radius: 1rem;
}

.text-shadow {
  text-shadow: 0 0 25px #000000;
}

// Stops padding from changing width/height
.noresize {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

// TRANSITIONS
.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: all 1500ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: all 1000ms;
}
